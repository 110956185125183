import React from "react"
import style from "./termsFormating.module.css"



const PrivacyPolicy = () => {

    return (    
        <div className={style.terms}>
            <div className={style.container}>
                <div className={style.content}>
                    <div className={style.title}>
                        PRIVACY POLICY 
                    </div>
                    <div className={style.subtitle}>
                        Privacy Policy
                    </div>
                    <div className={style.text}>
                        <p> We're UPlist, a marketplace for booking music artists. We've always put the privacy of our artists’ and customers’ data at the heart of everything we do. In particular, we never sell or trade personal data or email lists with third parties. We hope you’ll have seen already that we’re striving to do everything in your best interests, but just in case you’re not sure, this Privacy Policy goes into a bit more detail about why, how and when we collect personal information. 
                        <br /><br />    
                        For queries, please use the contact details given at the end of this policy.
                        </p>
                    </div>

                    <div className={style.subtitle}>
                        2.How we process data 
                    </div>
                    <div className={style.text}>
                        <p> Our business purpose is to provide a secure, accessible and easy-to-use online marketplace for connecting artists with customers who can book them. The personal information we collect as outlined in this document is collected on the legal basis of Legitimate Interests. “Legitimate Interests” means the interests of our company in conducting and managing our business, to enable us to give you the best service/products and the best and most secure experience. In other words, all of our data collection is done to support our purpose as a business, as described above. We only collect data which is necessary for this purpose. For each piece of personal information we collect, a Legitimate Interests Assessment has been carried out to ensure its collection supports our purpose and is weighed against your personal data rights.
                        <br /><br />
For example, we have an interest in making sure our emails are relevant for you, so we may process your information to send you emails that are tailored to your interests. It can also apply to processing that is in your interests as well. For example, we may process your information to protect you against fraud when transacting on our website, and to ensure our websites and systems are secure.
<br /><br />
When we process your personal information for our legitimate interests, we make sure to consider and balance any potential impact on you (both positive and negative), and your rights under data protection laws. Our legitimate business interests do not automatically override your interests - we will not use your Personal Data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law).

We process personal information for certain legitimate business purposes, which include some or all of the following:
<ul className={style.list}>
<li >
To enable you to access and use the website and/or mobile apps
</li>
<li >
Where the processing enables us to enhance, modify, personalise or otherwise improve our services / communications for the benefit of our customers
</li>
<li >
To identify and prevent fraud
</li>
<li >
To enhance the security of our network and information systems
</li>
<li >
To better understand how people interact with our websites (this may include the use of third party tools such as Google Analytics)
</li>
<li >
To provide email and text communications which are required or we think will be of interest to you (including service, support and administrative messages, reminders, technical notices, updates, security alerts, and information requested by you)
</li>
<li >
To determine the effectiveness of promotional campaigns and advertising
</li>
<li >
To administer rewards, surveys, sweepstakes, contests, or other promotional activities or events sponsored or managed by UPlist or our business partners
</li>
<li >
To comply with our legal obligations, resolve any disputes that we may have with any of our users, and enforce our agreements with third parties
</li>
</ul>

Whenever we process data for these purposes we will ensure that we always keep your Personal Data rights in high regard and take account of these rights. If you have any concerns about the way we process data, you have the right to object to processing that is based on our legitimate interests. Please use the email address at the bottom of this page to contact our data protection officer. Please bear in mind that if you object this may affect our ability to carry out tasks above for your benefit. </p>

                    </div>  
                    <div className={style.subtitle}>
                    3.What personal data do we collect?
                    </div>
                    <div className={style.text}>
                        <p>We collect personal data under the following categories:
                        <br /><br />
Data you enter when filling in any form on the website - such as when you register or update the details of your user account
<br /><br />
Data relating to your access and use of the website - such as when you search for artists, make or accept bookings, pay for bookings, book or pay for any associated services that may be available, post comments or reviews, or communicate with other users
<br /><br />
Data you consent to share with us from third parties - such as if you link your account on a social media site (e.g. Facebook) to your UPlist account, in which case we will obtain the Personal Information that you have provided to the third party site, to the extent allowed by your settings with the third party site and authorised by you
Data relating to your direct communications with Uplist
<br /><br />

UPlist may collect the following information about you:
<ul className={style.list}>
<li>
Your name, age/date of birth and gender
</li>
<li>
Your contact details: postal address including billing and delivery addresses, telephone numbers (including mobile numbers) and e-mail address
</li>
<li>
Purchases and orders made by you
</li>
<li>
Your online browsing activities on UPlist websites
</li>
<li>
Your password
</li>
<li>
Your communication and marketing preferences
</li>
Your interests, preferences, feedback and survey responses
<li>
Your location
</li>
<li>
Your correspondence and communications with Uplist
</li>
<li>
Other publicly available personal data, including any which you have shared via a public platform (such as a Twitter feed or public Facebook page)
</li>
</ ul>
<br /><br />
Our website is not intended for children and we do not knowingly collect data relating to children.
<br /><br />
We do not collect payment details. If you enter payment details into UPlist, these are sent directly to our payments provider, who store them securely, and you acknowledge and accept Stripe’s terms and policies at the point of purchase, as detailed on our payment checkout page.
<br /><br />
This list is not exhaustive and, in specific instances, we may need to collect additional data for the purposes set out in this Policy. Some of the above personal data is collected directly, for example when you set up an account on our website, submit an enquiry, or send a message to our customer services team. Other personal data is collected indirectly, for example your browsing activity. We may also collect personal data from third parties who have your consent to pass your details to us, or from publicly available sources.

</p>
                    </div>
                    <div className={style.subtitle}>
                    4. Referral and review services

                    </div>
                    <div className={style.text}>
                    The website provides various referral/review/invite services that allow you to invite your contacts to use the website or visit the website to complete a specific action (such as verifying a review).
We may integrate the website with third party sites such as Facebook, so that you can send invitation messages via the third party site itself. These messages will be sent by the third party site, and UPlist does not collect or retain the contact information that is used to send them.
<br /><br />
You may also send invitation/request emails via the website itself, in which case we will ask you for the email addresses to send these emails to. You can type in the email addresses manually, or you can request UPlist to import the contacts in your email account address book(s). In both cases, we will use the information sent to us for the sole purpose of sending your contacts a one-time email, inviting them to visit the website and/or complete a short task. With respect to referrals, we will also store the email addresses of your invitees to track if your friend joins UPlist in response to your referral.
<br /><br />
We do not store customer email addresses submitted by artists for review purposes.
<br /><br />
If you request us to import your contacts, we will collect, but not store, the username and password for the email account you wish to import your contacts from. We will use this information only for the purpose of importing your contacts.

</div>
                    <div className={style.subtitle}>    
                    5. Mobile data
                    </div>
                    <div className={style.text}>
                    When you use certain features of the platform - in particular our mobile applications - we may receive, store and process different types of information about your location, including general information (e.g. IP address) and more specific information (e.g., GPS-based functionality on mobile devices used to access the platform or specific features of the platform). If you access the platform through a mobile device and you do not want your device to provide us with location-tracking information, you can disable the GPS or other location-tracking functions on your device, provided your device allows you to do this. See your device manufacturer’s instructions for further details.

</div>
                    <div className={style.subtitle}>
                    6. Website cookies
                    </div>
                    <div className={style.text}>
                    Cookies are small pieces of data stored in your browser when you visit our website. We may use cookies to facilitate the data collection described in this policy, and we may allow other business partners to use cookies and other tracking technology on our website. For example, Google Analytics may store a cookie in your browser when you visit our website, which will allow us to gather anonymised statistics about which of our pages are visited most frequently.
                    <br /><br />
You can read more about Google's privacy policies in relation to cookies here.
<br /><br />
There are two types of cookies used on the website, namely “persistent cookies” and “session cookies”. Session cookies will normally expire when you close your browser, while persistent cookies will remain on your device after you close your browser, and can be used again the next time you access the website.
<br /><br />
We may, either directly or through third party companies and individuals we engage to provide services to us, also continue to track your behaviour on our own website and/or mobile apps for purposes of our own customer support, analytics, research, product development, fraud prevention, risk assessment, regulatory compliance, investigation, as well as to enable you to use and access the platform and pay for your activities on it. We may also, either directly or through third party companies and individuals we engage to provide services to us, track your behaviour on our own platform to market and advertise our services to you on the platform and third party websites.
<br /><br />
Most browsers automatically accept cookies, but you can modify your browser setting to decline cookies by visiting the Help portion of your browser’s toolbar. If you choose to decline cookies, please note that you may not be able to sign in, customise, or use some of the interactive features of the website.
Third parties may not collect information about users’ online activities on the website except as described in this policy.

</div>
                    <div className={style.subtitle}>
                    7. Other technologies
                    </div>
                    <div className={style.text}>
                    The Platform may also use other technologies with similar functionality to cookies, such as web beacons and tracking URLs to obtain Log Data about users. We may also use web beacons and tracking URLs in our messages to you to determine whether you have opened a certain message (email or text) or accessed a certain link.

</div>
                    <div className={style.subtitle}>
                    8. Analytics
                    </div>
                    <div className={style.text}>
                    When someone visits our website we use one or more third party services, including Google Analytics, to collect standard internet log information and details of visitor behaviour patterns. We do this to find out things like the number of visitors to different pages on our site, and their usage patterns within those pages. This information is only processed in a way which does not identify anyone. We do not make, and do not allow Google or other organisations to make, any attempt to find out the identities of those visiting our website.

</div>
                    <div className={style.subtitle}>
                    9.Third party integrations
                    </div>
                    <div className={style.text}>
                    Our website or mobile apps may use social and other plugins which are provided and operated by third party companies, such as Facebook’s Like Button or Google’s Maps integration.
                    <br /><br />
As a result of this, you may send to the third party company the information that you are viewing on a certain part of our platform. If you are not logged into your account with the third party company, then the third party may not know your identity. If you are logged into your account with the third party company, then the third party may be able to link information about your visit to our platform to your account with them. Similarly, your interactions with the social plugin may be recorded by the third party.
<br /><br />
Please refer to the third party’s Privacy Policy to find out more about its data practices, such as what data is collected about you and how the third party uses such data.

</div>
                    <div className={style.subtitle}>
                    10. When do we share or disclose personal data?
                    </div>

                    <div className={style.text}>
                    We will not transfer, store, use or process your information, including any Personal Information, to countries outside of the European Economic Area (“EEA”).
                    <br /><br />
Your Personal Information may be disclosed as follows:
<br /><br />
As an artist, your public profile page will always include some minimum information such as your name, your public profile photo and public reviews and statistics related to your previous bookings. Parts of your public listing page may be displayed in other parts of the website to other users for marketing purposes. The website may also display your approximate geographic location on a map, such that a customer can see the general area of the services offered.
<br /><br />
As an artist, your public profile and results on public listing pages may be included in search engine indexes, in which case some details from your public profile may be published as search results. This option is enabled by default, and you may opt out of this feature by changing your settings on the website. If you change your settings or the information on your public profile, these search engines may not update their databases quickly or at all. We do not control the practices of third-party search engines, and they may use caches containing outdated information, including any information indexed by the search engine before you change your settings or the information on your public profile.
<br /><br />
When you submit a request to book an artist, your full name will become visible to the artist. In addition, if you request a call with the artist by phone and the artist accepts your request, we will share your phone number to enable them to make or accept a call. We may also share your phone number when a booking is confirmed in case the other party needs to contact you.
<br /><br />
When your request to book services is accepted or when you accept a request to book your services, we will disclose some of your Personal Information to the other party involved. However, your billing information will never be shared with another user.
After a booking service has been completed, we will ask you to review the client or artist. If you choose to provide a review, your review may be public on the website.
We may also publish, disclose and use Aggregated Information and non-personal information for industry analysis, demographic profiling, marketing and advertising, and other business purposes.

</div>
                    <div className={style.subtitle}>
                    11. Business transfers
                    </div>
                    <div className={style.text}>
                    If UPlist undertakes or is involved in any merger, acquisition, reorganisation, sale of assets or bankruptcy or insolvency event, then we may sell, transfer or share some or all of our assets, including your Personal Information. In this event, we will notify you before your Personal Information is transferred and becomes subject to a different privacy policy.

</div>
                    <div className={style.subtitle}>    
                    12. Legal requirements
                    </div>
                    <div className={style.text}>

We may disclose your Personal Information if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or property of UPlist, (iii) act in urgent circumstances to protect the personal safety of users of the website or the public, or (iv) protect against legal liability.
</div>
                    <div className={style.subtitle}>
                    13. How do we protect your personal data?

                    </div>
                    <div className={style.text}>
                    We take the security of your personal data very seriously. We have implemented various strategies, controls, policies and measures to keep your data secure and keep these measures under close review. We protect your data by using encryption techniques and we use other safeguards such as firewalls and password protection. This means that your data is protected and only accessible by co-workers who need it to carry out their job responsibilities. We also ensure that there are strict physical controls in our buildings which restricts access to your personal data to keep it safe.

</div>
                    <div className={style.subtitle}>
                    14. How long do we keep your personal data?
                    </div>
                    <div className={style.text}>
                    We will not retain your personal data for longer than is necessary to fulfil the purposes for which we collected that personal data, unless the law permits or requires that we retain it for longer. The retention period varies depending on the purpose of the processing. For example, the data collected during your purchase of goods on www.uplist.com are retained in accordance with local tax law (seven years in the UK), while those data used to send you our marketing communications are retained until you ask to be unsubscribed.

</div>
                    <div className={style.subtitle}>
                    15. What are your rights?
                    </div>
                    <div className={style.text}>
                    Under the General Data Protection Regulation, you have the right to access, rectify, port and erase your data. You also have the right to object to and restrict certain processing of your data. This includes:
                    <br /><br />
the right to object to our processing of your data for direct marketing, which you can exercise by using the "unsubscribe" link in such marketing communications; and
<br /><br />
the right to object to our processing of your data where we are performing a task in the public interest or pursuing our legitimate interests or those of a third party.
<br /><br />
You may access, review, and update much of the information you have submitted to us at any time via your account. You may also contact us to access, correct, amend or delete information that we hold about you, as explained in more detail in the 'Contact Us' section below.
<br /><br />
If you are unhappy with the way we are using your personal data you can also contact and are free to lodge a complaint with your local data protection regulator.

</div>
                    <div className={style.subtitle}>    
                    16. Changes to this Privacy Policy
                    </div>
                    <div className={style.text}>
                    UPlist reserves the right to modify this Privacy Policy at any time in accordance with this provision. If we make changes to this Privacy Policy, we will post the revised Privacy Policy on the website and update the “Last Updated” date at the top of this Privacy Policy. We will also provide you with notice of the modification by email at least thirty (30) days before the date they become effective. If you disagree with the revised Privacy Policy, you may cancel your Account. If you do not cancel your Account before the date the revised Privacy Policy becomes effective, your continued access to or use of the website will be subject to the revised Privacy Policy.

</div>
                    <div className={style.subtitle}>
                    17. Contact Us
                    </div>
                    <div className={style.text}>
                    If you have any questions or complaints about this Privacy Policy or UPlist’s information handling practices, you may email us at privacy@uplist.com or contact us at:
                    <br /><br />

UPlist
<br /><br />
Birmingham 
<br /><br />
United Kingdom
</div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;






