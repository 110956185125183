import React from "react"
import style from "./termsFormating.module.css"



const TermsAndConditions = () => {

    return (    
        <div className={style.terms}>
            <div className={style.container}>
                <div className={style.content}>
                    <div className={style.title}>
                    UPlist Terms of Use
                    </div>
                    <div className={style.subtitle}>
                    1. Introduction
                    </div>
                    <div className={style.text}>
                    Welcome to UPlist, a music artist bookings platform that connects artists, agents, and venues for live performances. UPlist is owned and operated by UPlist Ltd. and its affiliates (“UPlist”, “we”, “us”, “our”). These terms of use (“Terms”) govern your access and use of our website, mobile application, and any other online platform or service that we provide or make available to you (collectively, the “Platform”). By accessing or using the Platform, you agree to be bound by these Terms. If you do not agree with these Terms, you must not access or use the Platform.

                    </div>
                    <div className={style.subtitle}>
                    2. Eligibility
                    </div>
                    <div className={style.text}>
                    To access and use the Platform, you must be at least 18 years old and have the legal capacity to enter into a binding agreement. You must also comply with all applicable laws, rules, and regulations in your jurisdiction. If you are accessing or using the Platform on behalf of an entity, such as an artist, an agent, or a venue, you represent and warrant that you have the authority to bind that entity to these Terms.
                    </div>
                    <div className={style.subtitle}>
                    3. Registration
                    </div>
                    <div className={style.text}>
                    To access and use certain features and functionalities of the Platform, you may need to register for an account (“Account”). You are responsible for providing accurate, complete, and current information when registering for an Account and updating your Account as necessary. You are also responsible for maintaining the security and confidentiality of your Account credentials and for any activity that occurs under your Account. You must notify us immediately if you suspect any unauthorized access or use of your Account.
                    </div>

                    <div className={style.subtitle}>
                    4. Services
                    </div>
                    <div className={style.text}>
                    The Platform provides a marketplace where artists, agents, and venues can discover, communicate, negotiate, book, and manage live performances (“Services”). We do not own, control, or endorse any of the artists, agents, or venues that use the Platform or offer their services through the Platform. We are not a party to any agreements or transactions between the users of the Platform. We are not responsible for the quality, safety, legality, suitability, availability, or reliability of any services offered or provided by the users of the Platform. We do not guarantee that any user will perform or honour their obligations under any agreement or transaction. You acknowledge and agree that you use the Platform and the Services at your own risk.
                    </div>
                    <div className={style.subtitle}>
                    5. Fees and Payments
                    </div>
                    <div className={style.text}>
                    We may charge fees for certain features and functionalities of the Platform or the Services (“Fees”). The Fees may vary depending on the type of user, the type of service, the duration of service, or other factors. We will notify you of any applicable Fees before you incur them. You are responsible for paying all Fees that you owe to us in a timely manner. We may change the Fees at any time at our sole discretion. We may also offer promotional discounts or incentives for certain users or services at our sole discretion.
<br /><br />
If you are an artist or an agent who offers or provides services through the Platform, you authorize us to collect payments from the venues on your behalf and to deduct our Fees from such payments before remitting them to you (“Payouts”). You are solely responsible for determining and complying with any tax obligations arising from your use of the Platform or the Services. We are not liable for any taxes or fees that you may incur as a result of receiving Payouts from us.
<br /><br />
If you are a venue who requests or receives services through the Platform, you authorize us to charge your payment method for any payments that you owe to us or to the artists or agents who provide services to you (“Payments”). You are solely responsible for ensuring that your payment method is valid and has sufficient funds to cover your Payments. We are not liable for any fees or charges that you may incur as a result of using your payment method on the Platform.

                    </div>
                    <div className={style.subtitle}>
                    6. Cancellations and Refunds
                    </div>
                    <div className={style.text}>
                    If you are an artist or an agent who offers or provides services through the Platform, you agree to honour all bookings that you accept through the Platform and to perform your services in a professional and satisfactory manner. You may cancel a booking only in accordance with our cancellation policy (“Cancellation Policy”), which we will provide to you when you register for an Account. If you cancel a booking in violation of our Cancellation Policy, we may withhold or deduct any Payouts that you are entitled to receive from us.
                    <br /><br />
If you are a venue who requests or receives services through the Platform, you agree to honor all bookings that you make through the Platform and to pay for the services that you receive in full. You may cancel a booking only in accordance with our Cancellation Policy. If you cancel a booking in violation of our Cancellation Policy, we may charge your payment method for any Payments that you owe to us or to the artists or agents who provide services to you.
<br /><br />
We may issue refunds to either party in accordance with our Cancellation Policy or at our sole discretion. We are not obligated to issue any refunds for any reason.
                    
                    </div>
                    <div className={style.subtitle}>
                    7. Content

                    </div>
                    <div className={style.text}>
                    The Platform may allow you to create, upload, post, share, or otherwise make available any text, images, videos, audio, or other materials or information (“Content”). You are solely responsible for your Content and the consequences of making it available on the Platform. You represent and warrant that you own or have the necessary rights and permissions to use and share your Content on the Platform and that your Content does not infringe or violate any intellectual property, privacy, or other rights of any third party.
                    <br /><br />
We do not claim any ownership or control over your Content. However, by making your Content available on the Platform, you grant us a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, modify, adapt, distribute, display, perform, and otherwise exploit your Content in connection with the Platform and our business. You also grant us the right to use your name, likeness, voice, and image in connection with your Content.
<br /><br />
We reserve the right to review, monitor, remove, or disable access to any Content on the Platform at any time and for any reason at our sole discretion. We may also take legal action against anyone who violates these Terms or infringes or violates any rights of any third party.

                    </div>
                    <div className={style.subtitle}>
                    8. Prohibited Conduct
                    </div>
                    <div className={style.text}>
                    You agree not to engage in any of the following prohibited conduct when accessing or using the Platform or the Services:
<ul className={style.list}>
<li>
    Using the Platform for any illegal or unlawful purpose or activity.
    Violating any laws, rules, regulations, or terms and conditions applicable to your access or use of the Platform.

</li>
<li>
    Infringing or violating the intellectual property, privacy, or other rights of any third party.
    Harassing, threatening, abusing, or harming any other user of the Platform or any other person or entity.
</li>
<li>
    Posting or sharing any Content that is offensive, obscene, defamatory, discriminatory, or deceptive.

</li>
<li>
Misrepresenting your identity, affiliation, or status.
Interfering with the operation, functionality, performance, availability, or accessibility of the Platform.
</li>
<li>   
Attempting to gain unauthorized access to any part of the Platform or any user account.
Reverse engineering, decompiling, disassembling, or otherwise attempting to discover the source code or underlying structure of the Platform.
</li>
<li>
Developing, distributing, or using any software or tools designed to harm, exploit, or extract data from the Platform.
</li>
</ul>
                    
                    </div>
                    <div className={style.subtitle}>
                    9. Disputes
                    </div>
                    <div className={style.text}>
                    If you have a dispute with another user of the Platform, you agree to resolve the dispute directly with the other user. We are not obligated to mediate, intervene, or resolve any disputes between the users of the Platform. However, we may choose to do so at our sole discretion.
                    
                    </div>
                    <div className={style.subtitle}>
                    10. Limitation of Liability
                    </div>
                    <div className={style.text}>
                        
To the maximum extent permitted by law, we and our affiliates, directors, officers, employees, agents, and partners will not be liable for any direct, indirect, incidental, special, consequential, punitive, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses, arising out of or relating to your access or use of, your inability to access or use, or your reliance on any aspect of the Platform, whether based on warranty, contract, tort, negligence, or any other legal theory, and whether or not we have been informed of the possibility of such damages.

                    </div>
                    <div className={style.subtitle}>
                    11. Indemnification
                    </div>
                    <div className={style.text}>
                    You agree to indemnify, defend, and hold harmless us and our affiliates, directors, officers, employees, agents, and partners from and against any and all claims, liabilities, damages, losses, costs, expenses, fees (including reasonable attorneys' fees and costs) that such parties may incur as a result of or arising from your (or anyone using your account's) violation of these Terms. We reserve the right to assume the exclusive defence and control of any matter otherwise subject to indemnification by you and, in such case, you agree to cooperate with our defence of such claim.

                    </div>
                    <div className={style.subtitle}>
                    12. Termination
                    </div>
                    <div className={style.text}>
                    We may terminate your access to and use of the Platform at any time and for any reason at our sole discretion, without notice or liability to you. Upon termination, all rights and licenses granted to you in these Terms will immediately cease. You may terminate your access to and use of the Platform at any time and for any reason by deleting your Account or contacting us.

                    </div>
                    <div className={style.subtitle}>
                    13. Governing Law
                    </div>
                    <div className={style.text}>
                    These Terms and any disputes or claims arising out of or in connection with them will be governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflict of laws principles. You agree to submit to the exclusive jurisdiction of the courts located in the United Kingdom to resolve any legal matter arising from these Terms.

                    </div>
                    <div className={style.subtitle}>
                    14. Changes to Terms
                    </div>
                    <div className={style.text}>
                    We reserve the right to modify these Terms at any time in accordance with this provision. If we make changes to these Terms, we will post the revised Terms on the Platform and update the “Last Updated” date at the top of these Terms. We will also provide you with notice of the modifications by email at least thirty (30) days before the date they become effective. If you disagree with the revised Terms, you may terminate your access to and use of the Platform. If you do not terminate your access to and use of the Platform before the date the revised Terms become effective, your continued access to or use of the Platform will be subject to the revised Terms.

                    </div>
                    <div className={style.subtitle}>
                    15. Contact Us
                    </div>
                    <div className={style.text}>
                    If you have any questions or concerns about these Terms, you may email us at support@uplist.com or contact us at:
                    <br /><br />

                    UPlist
                    <br /><br />
                    Birmingham, 
                    <br /><br />
                    United Kingdom
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditions;