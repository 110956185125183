import React from 'react';
import CardBooking from './cardbookingfeed';

function BookingsFeedItem2() {
  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <CardBooking />
      
    </div>
  );
}

export default BookingsFeedItem2;
